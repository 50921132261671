import React, {useState, useEffect} from "react"

import Layout from '../../components/layout';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import FreeTrial from "../../components/free-trial";
import SEO from "../../components/seo";

import LeftFeature from '../../components/left-feature';
import FeaturePararaph from '../../components/feature-paragraph'

import calculatorSrc from '../../images/calculator.svg';
import multiDeviceSrc from '../../images/multi-device.png';


const DecimalCalculator = () => {

	let [hours, setHours] = useState(null);
	let [minutes, setMinutes] = useState(null);

	let [hoursDecimal, setHoursDecimal] = useState(0);

	let [clipboardBtnText, setClipboardBtnText] = useState(null);

	useEffect(() => {
		if(hours != null || minutes != null){
			let hoursWorkedDecimal = hours != null ? hours : 0;
			let minutesDecimalWorked = minutes != null ? minutes : 0;
			hoursWorkedDecimal += (minutesDecimalWorked / 60);
			setHoursDecimal(hoursWorkedDecimal.toFixed(2));
		}else{
			setHoursDecimal(null);
		}
	}, [hours, minutes])

	return (
		<div className="mt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
			<div>
	          <label htmlFor="hours_worked" class="block text-sm font-medium leading-5 text-gray-600">Hours Worked</label>
	          <div className="mt-1 relative">
	            <input id="hours_worked" min="0" value={hours} onChange={(e) => {
	            	if(e.target.value === ''){
	            		setHours(null);
	            	}else{
		            	const val = Math.max(0, parseInt(e.target.value, 10));
		            	setHours(val)
		            }
	            }} type="number" placeholder="Enter hours worked" className="form-input rounded-md border-highlight-400 border-2 py-3 px-4 block w-full focus:outline-none transition ease-in-out duration-150" />
	          </div>
	        </div>
	        <div>
	          <label htmlFor="minutes_worked" className="block text-sm font-medium leading-5 text-gray-600">Minutes Worked</label>
	          <div class="mt-1 relative">
	            <input id="minutes_worked" value={minutes} onChange={(e) => {
	            	if(e.target.value === ''){
	            		setMinutes(null);
	            	}else{
	            		const val = Math.max(0, Math.min(59, parseInt(e.target.value, 10)));
	            		setMinutes(val);
	            	}
	            }} type="number" placeholder="Enter minutes worked" className="form-input rounded-md border-highlight-400 border-2 py-3 px-4 block w-full focus:outline-none transition ease-in-out duration-150" />
	          </div>
	        </div>
	        {hoursDecimal != null && 
	        	<div className="col-span-1 lg:col-span-2 text-center">
		        	<h2 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">{hours != null && `${hours} ${hours == 1 ? 'hour': 'hours'}`} {minutes != null && `${minutes} minutes`} = <span className="text-highlight-400">{hoursDecimal} hours</span></h2>
		        	<button type="button" onClick={() => {
		        		navigator.clipboard.writeText(hoursDecimal);
		        		setClipboardBtnText('Copied')
		        		setTimeout(() => {
		        			setClipboardBtnText(null);
		        		}, 1000)
		        	}} className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-highlight-600 focus:outline-none focus:outline-none transition ease-in-out duration-150">
		              {clipboardBtnText == null && <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" /></svg>} {clipboardBtnText != null ? clipboardBtnText : ' Copy to clipboard'}
		            </button>
		        </div>
		    }
	    </div>
	)
}

const HoursMinutesToDecimalCalculator = () => (
  <Layout>
    <SEO 
    	title="Convert Hours and Minutes to Decimal Timesheet Calculator"
    	description="Convert hours and minutes to decimal with our free calculator. Enter in hours and minutes to decimal instantly with our handy calculator."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		<div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
		  <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
		     <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
			    <div className="mb-8 lg:mb-0 lg:col-start-1 flex flex-col">
			    	<div className="flex justify-center">
			    		<img className="w-1/4" src={calculatorSrc} alt="Calculator" />
			    	</div>
					<DecimalCalculator />
			    </div>
			    <div className="lg:col-start-2 lg:pl-8">
			      <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
			        <p className="leading-6 text-highlight-600 font-semibold tracking-tight uppercase">Hours and Minutes to Decimal Time Calculator</p>
			        <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">Convert hours and minutes to decimal with our free calculator.</h1>
			        <div className="prose text-gray-500">
			          <p>A common task with timesheets is figuring out the decimal equivalent for the hours and minutes an employee worked.</p>
					  <p>For example, <strong>30 hours 30 minutes</strong> is <strong>30.5</strong> hours in decimal format but it can get more complex. If you're a math whizz, you probably know what <strong>30 hours 42 minutes</strong> is in decimal.</p>
			          <p>However if you are like us (or need your morning cup of coffee!), we use computers to do it for us. So we thought, let's make a <strong>free</strong> calculator to help everyone else as well!</p>
			        </div>
			      </div>
			    </div>
			  </div>
			  <LeftFeature 
			    	sectionTitle="Stop doing manual timesheets forever" 
			    	title="Move to Cloud-based Time and Attendance Software" 
			    	body={
			    		<div>
					        <FeaturePararaph>
					          The above calculation is just one of many pains in managing employee time and attendance.
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	If you had a few minutes, we'd love for you to check out TimeKeeper. Our simple solution automates the pain of employee timesheets, saving you time and money so you can focus on other tasks or just relax!
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={multiDeviceSrc}
			    	imgAlt="Move to Cloud Based Time and Attendance Software"
			    />
		  </div>
		</div>
	  	
	</div>
	<FreeTrial showDemoBooking={true} part1={<span>Ready to make the first step to <span className="text-highlight-600">automated</span> <br /> employee timesheets?</span>} />
	<Footer/>
  </Layout>
)

export default HoursMinutesToDecimalCalculator
